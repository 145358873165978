import React, { useState } from "react";
import axios from "../services/api";
import { Redirect } from "react-router-dom";

import { getUserDetails, isLoggedIn, setScopeId, setScopeType } from "../utils/utils";

function Landing(props) {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const handleEmailChange = e => {
    setEmail(e.target.value)
  };
  
  const handlePasswordChange = e => {
    setPassword(e.target.value)
  };

  const handleLogin = e => {
    e.preventDefault();

    axios
      .post("/api-token-auth/", {email: email, password: password, error: error})
      .then(res => {
        window.localStorage.setItem("token", res.data.token);
        axios.defaults.headers.common["Authorization"] = res.data.token;
        getUserDetails().then((res) => {
          if(res) {
            setScopeType(res.scope_type)
            setScopeId(res.scope_id)
          }
          props.history.push("/job-logs");
          // trigger a refresh (standard practice for after logging in)
          props.history.go();
        })
      })
      .catch(error => {
        console.log(error);
        setError("Unable to log in with provided credentials")
      });
  };

  return !isLoggedIn() ? (
    <div id="landing">
      <h3 className="secondary mb-5">Login</h3>
      <div className="error">{error}</div>
      <form className="login m-auto">
        <div className="form-group">
          <input
            className="form-control"
            type="text"
            placeholder="Email Address"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="form-group">
          <input
            className="form-control"
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <button
          className="btn btn-block mt-5"
          type="submit"
          onClick={handleLogin}
        >
          Submit
        </button>
      </form>
    </div>
  ) : (
    <Redirect to='/job-logs'/>
  )
}

export default Landing;
