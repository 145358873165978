import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";

import { PATHS } from "../utils/constants";
import {
  isLoggedIn,
  logout,
  isFluidtechEmployee,
  isAdminOrManager
} from "../utils/utils";

import logo from "../static/images/logo-3.png";

function Header(props) {
  const [canEditLogs, setCanEditLogs] = useState(false);
  const [canReport, setCanReport] = useState(false);

  useEffect(() => {
    if (isFluidtechEmployee()) {
      setCanEditLogs(true);
    }
    if (isAdminOrManager()) {
      setCanReport(true);
    }
  }, [])

  const { pathname } = props.location;
  const { history } = props;

  return (
    <nav className="navbar navbar-expand navbar-dark secondary-bg">
      <Link to={PATHS.HOME} className="navbar-brand">
        <img alt="Logo" src={logo} />
      </Link>
      {isLoggedIn() && (
        <ul className="navbar-nav ml-auto">
          <li
            className={`nav-item ${pathname === PATHS.HOME ? "active" : ""}`}
          >
            <Link to={PATHS.HOME} className="nav-link secondary">
              Home
            </Link>
          </li>
          {canEditLogs && (
            <>
              <li
                className={`nav-item ${pathname.startsWith(PATHS.ADD_LOG) ? "active" : ""
                  }`}
              >
                <Link to={PATHS.ADD_LOG} className="nav-link secondary">
                  {/\/\d/.test(pathname) ? "Edit Log" : "Add Log"}
                </Link>
              </li>
              {canReport && (
                <>
                  <li
                    className={`nav-item ${pathname.startsWith(PATHS.DETAIL_REPORT) ? "active" : ""
                      }`}
                  >
                    <Link to={PATHS.DETAIL_REPORT} className="nav-link secondary">
                      Detail Report
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${pathname.startsWith(PATHS.SUMMARY_REPORT) ? "active" : ""
                      }`}
                  >
                    <Link
                      to={PATHS.SUMMARY_REPORT}
                      className="nav-link secondary"
                    >
                      Summary Report
                    </Link>
                  </li>
                </>
              )}
            </>
          )}
          <li className="nav-item">
            <div
              className="nav-link secondary"
              onClick={() => logout(history)}
            >
              Logout
            </div>
          </li>
        </ul>
      )}
    </nav>
  );
}

export default withRouter(Header);
