import React, { useEffect, useState } from "react";
import moment from "moment";

import ReactTable from "react-table-6";
import axios, { config } from "../services/api";

function SummaryReport(props) {

    const [jobLogs, setJobLogs] = useState([]);
    const [error, setError] = useState("");
    const [date, setDate] = useState(moment());

    useEffect(() => {
      fetchReports(date.month() + 1, date.year())
    }, [date])

    const fetchReports = (month, year) => {
      axios
          .get(`/client/summary-report/?month=${month}&year=${year}`, config())
          .then(res => {
            var data = res.data
            if(res.data.length > 0) {
              // removing the total_bbl count from the data object to not be displayed as a row on the table
                data.splice(-1,1)
            }
            setJobLogs(data)
          })
          .catch(error => {
            console.log(error);
            setError("Unable to get the report.")
          });
    }

    const handleDateChange = (event) => {
      setDate(moment(event.target.value))
    }
        
    const exportToCSV = (option) => {
      var query_string = `/client/${option}-report-csv/?month=${date.month() + 1}&year=${date.year()}`
        axios
            .get(query_string, config())
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${option}-report.csv`)
                document.body.appendChild(link)
                link.click()
                link.remove()
            })
            .catch(error => {
                console.log(error);
                setError("Unable to export to CSV")
            });
    }

        return (
            <div id="job-logs" className="report-table">
                {error && <div className='error'>{error}</div>}
                <div className="date-picker">
                  <input type="month" defaultValue={date.format("YYYY-MM")} onChange={(event) => handleDateChange(event)}></input>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                      className="btn mb-4 spaced"
                      onClick={() => exportToCSV("summary")}
                  >
                      Export to CSV
                  </button>

                  <button
                      className="btn mb-4 spaced"
                      onClick={() => exportToCSV("state")}
                  >
                      State Report
                  </button>
                </div>
    
                <ReactTable
                data={jobLogs}
                defaultPageSize={jobLogs.length < 10 ? 10 : 20}
                filterable
                defaultFilterMethod={(filter, row) =>
                    String(row[filter.id])
                    .toLowerCase()
                    .includes(filter.value.toLowerCase())
                }
                defaultFiltering
                columns={[
                    {
                        Header: "Billed",
                        id: "billed",
                        accessor: "billed",
                        Cell: ({ value }) => (value ? "YES" : "NO"),
                        filterMethod: (filter, row) => {
                          if (filter.value === "all") return true;
                          const rowId = row[filter.id];
                          return filter.value === "true" ? rowId : !rowId;
                        },
                        Filter: ({ filter, onChange }) => (
                          <select
                            onChange={event => onChange(event.target.value)}
                            style={{ width: "100%", height: "100%" }}
                            value={filter ? filter.value : "all"}
                          >
                            <option value="all">ALL</option>
                            <option value="true">YES</option>
                            <option value="false">NO</option>
                          </select>
                        )
                    },
                    { Header: "Customer", accessor: "operator.name" },
                    { Header: "Location", accessor: "well.location", minWidth: 200 },
                    { Header: "Waste Type", accessor: "waste_type.name" },
                    { Header: "Price", accessor: "price"},
                    { Header: "Date", accessor: "date" },
                    { Header: "Truck Driver", accessor: "truck_driver.name" },
                    { Header: "#BBL", accessor: "bbl_count" },
                    { Header: "Amount", accessor: "amount" },
                ]}
                className="-striped -highlight"
                />
          </div>
        );
    }
    
    export default SummaryReport;
    