import React from "react";
import { validateEmpty } from "../services/validators";

function FormInputField(props) {

  const { handleOnChange, value, label, name, isRequired, type, disabled, error = null } = props;

  const onChange = e => {
    let currentError = isRequired ? validateEmpty(e.target.value) : null;
    handleOnChange(e, currentError)
  }

  return (
    <div className="form-group mb-4">
      <label>
        {label}
        {isRequired && <span className="required"> *</span>}
        {error && <span className='error'> {error}</span>}
      </label>
      {type === "textarea" ? (
        <textarea
          className="form-control"
          name={name}
          required={isRequired}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
      ) : (
        <input
          className="form-control"
          name={name}
          type={type}
          required={isRequired}
          value={value}
          disabled={disabled}
          onChange={onChange}
          checked={value}
        />
      )}
    </div>
  );
}

export default FormInputField;
