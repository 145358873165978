import React, { useEffect, useState } from "react";
import moment from "moment";
import ReactTable from "react-table-6";
import axios, { config } from "../services/api";

function DetailReport(props) {

    const [jobLogs, setJobLogs] = useState([]);
    const [error, setError] = useState("");
    const [date, setDate] = useState(moment());

    useEffect(() => {
      fetchReports(date.month() + 1, date.year())
    }, [date]);

    const fetchReports = (month, year) => {
      axios
          .get(`/client/detail-report/?month=${month}&year=${year}`, config())
          .then(res => {
            setJobLogs(res.data)
          })
          .catch(error => {
            console.log(error);
            setError("Unable to get the report.");
          });
    }

    const handleDateChange = (event) => {
      setDate(moment(event.target.value))
    }
        
    const exportToCSV = () => {
        axios
            .get(`/client/detail-report-csv/?month=${date.month() + 1}&year=${date.year()}`, config())
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'detail-report.csv')
                document.body.appendChild(link)
                link.click()
                link.remove()
            })
            .catch(error => {
                console.log(error);
                setError("Unable to export to CSV.")
            });
            
    }

        return (
            <div id="job-logs" className="report-table">
              {error && <div className='error'>{error}</div>} 
              <div className="date-picker">
                <input type="month" defaultValue={date.format("YYYY-MM")} onChange={(event) => handleDateChange(event)}></input>
              </div>
              <div className="d-flex justify-content-end">
                <button
                    className="btn mb-4"
                    onClick={() => exportToCSV()}
                >
                    Export to CSV
                </button>
              </div>
    
            <ReactTable
              data={jobLogs}
              defaultPageSize={jobLogs.length < 10 ? 10 : 20}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id])
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              }
              defaultFiltering
              columns={[
                { Header: "Date", accessor: "date" },
                { Header: "Customer", accessor: "operator.name" },
                { Header: "Drilling Rig", accessor: "drilling_rig.name" },
                { Header: "Job #", accessor: "job_number" },
                { Header: "Location", accessor: "well.location", minWidth: 200 },
                { Header: "Price", accessor: "price" },
                { Header: "Solids %", accessor: "solids_percentage" },
                { Header: "#BBL", accessor: "bbl_count" },
                { Header: "Waste Type", accessor: "waste_type.name" },
                { Header: "Trucking Company", accessor: "trucking_company.name" },
                { Header: "Truck Driver", accessor: "truck_driver.name" },
                {
                  Header: "Bill To",
                  id: "billed_to",
                  accessor: row =>
                    row.billed_to === "operator"
                      ? row.operator && row.operator.name
                      : row.trucking_company && row.trucking_company.name
                },
                { Header: "Comment", accessor: "comment", minWidth: 200 },
                { Header: "Amount", accessor: "amount" },
                {
                  Header: "Billed",
                  id: "billed",
                  accessor: "billed",
                  Cell: ({ value }) => (value ? "YES" : "NO"),
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") return true;
                    const rowId = row[filter.id];
                    return filter.value === "true" ? rowId : !rowId;
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%", height: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">ALL</option>
                      <option value="true">YES</option>
                      <option value="false">NO</option>
                    </select>
                  )
                },
                {
                  Header: "Operator / Well Name",
                  accessor: "operator_slash_location",
                  minWidth: 200
                },
                {
                  Header: "ND Well Location",
                  accessor: "nd_well_location",
                  minWidth: 150
                },
                { Header: "Well File Number", accessor: "nd_well_file_number" }
              ]}
              className="-striped -highlight"
            />
          </div>
        );
    }
    
    export default DetailReport;
    