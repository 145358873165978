import React from "react";
import ReactModal from "react-modal";

import close from "../../static/images/icons/close-icon.png";

function Modal(props) {

    return (
      <ReactModal
        isOpen={props.isOpen}
        className={`modal-container ${props.className}`}
        overlayClassName="overlay"
        ariaHideApp={false}
      >
        <div className="header">
          <div className="close" onClick={props.onClose}>
            <img src={close} alt="close" />
          </div>
          <h3 className="title">{props.title}</h3>
        </div>
        <div className="body">
          {props.children}

          <div className="actions">
            <button className="btn muted" onClick={props.onClose}>
              Cancel
            </button>
            <button
              type="submit"
              className="btn primary"
              form={props.formId}
            >
              Save
            </button>
          </div>
        </div>
      </ReactModal>
    );
}

export default Modal;
