import axios from 'axios'

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export const config = () => {
  return {
    headers: {
      "Authorization": "JWT " + window.localStorage.getItem("token"),
    }
  }
}