export const validateEmpty = value => {
    if(!value || value.length === 0 ) {
        return "This field is required";
    }
    return null;
}

export const validateEmptyChoice = value => {
    if(!value || Number(value) === 0) {
        return "This field is required";
    }
    return null;
}

export const validateNotNegative = value => {
    if(value && Number(value) < 0) {
        return "This field cannot be a negative value"
    }
    return null;
}

export const validateValidPercentage = value => {
    if(value && !(Number(value) <= 100 && Number(value) >= 0)) {
        return "The percentage must be between 0 and 100"
    }
    return null;
}

export const validateInteger = value => {
    if(value && !isNaN(value) && parseInt(Number(value)) === Number(value)) {
        return null;
    }
    return "The value must be an integer (cannot contain decimal values)"
}

// returns null if there are no errors, but returns a map of all the errors if there are any
export const validateForm = (form, validators) => {
    let errors = {}
    for(let field in form) {
        if(validators[field]) {
            for(let validator of validators[field]) {
                let error = validator(form[field].value);
                if(error) {
                    errors[field] = errors[field] ? errors[field] + ', ' + error : error 
                }
            }
        }
    }
    return Object.entries(errors).length === 0 ? null : errors;
}