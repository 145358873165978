import axios, { config } from "../services/api";

export const isLoggedIn = () => window.localStorage.token !== "" && window.localStorage.token !== undefined;

export const logout = history => {
  window.localStorage.token = "";
  window.localStorage.scope = "";
  window.localStorage.scope_id = "";
  history.push("/");
  history.go();
};

export const setScopeType = scope => {
  if(scope === 'admin scope') {
    window.localStorage.setItem('scope', 'admin')
  }
  if(scope === 'disposal') {
    window.localStorage.setItem('scope', 'employee')
  }
  if(scope === 'billing manager scope') {
    window.localStorage.setItem('scope', 'billing')
  }
  if(scope === 'trucking company') {
    window.localStorage.setItem('scope', 'trucking')
  }
  if(scope === 'operator') {
    window.localStorage.setItem('scope', 'operator')
  }
}

export const setScopeId = id => {
  window.localStorage.setItem('scope_id', id)
}

export const getUserScope = () => window.localStorage.scope !== "" && window.localStorage.scope !== undefined ? window.localStorage.scope : null;

export const getUserScopeId = () => getUserScope() !== null && window.localStorage.scope_id !== "" && window.localStorage.scope_id !== undefined ? Number(window.localStorage.scope_id) : null;

export const isFluidtechEmployee = () => {
  let scope = getUserScope()
  return (
    scope === "employee" ||
    scope === "admin" ||
    scope === "billing"
  );
};

export const isAdminOrManager = () => {
  let scope = getUserScope()
  return scope === "admin" || scope === "billing"
}

export const getFormattedUserScope = () => {
  let scope = window.localStorage.scope
  if(scope === "employee") {
    return "Fluidtech employee"
  }
  if(scope === "admin") {
    return "admin"
  }
  if(scope === "billing") {
    return "billing manager"
  }
  return "client representative"
}

export const getUserDetails = async () => {
  if (!isLoggedIn()) {
    return { scope_type: null };
  }
  return await axios
    .get("/user/get-user-details", config())
    .then(res => {
      if (res.data.success) {
        setScopeId(res.data.data.scope_id)
        return res.data.data;
      }
      console.log(res.data.error);
    })
    .catch(error => {
      console.log(error);
      return 
    });
};

export const updateEmployeeScopeType = async (id) => {
  if (getUserScope() !== 'employee') {
    return
  }
  return await axios
    .put("/user/update-employee-disposal/", {scope_id: id}, config())
    .then(res => {
      if(res.data.success) {
        if(res.data.data) {
          setScopeId(res.data.data.scope_id)
        }
        return res.data.data
      }
      console.log(res.data.error);
    })
    .catch(error => {
      console.log(error);
    });
};

export const convertToFormFields = (form) => {
  Object.keys(form).map((key) => form[key] = {value: form[key], error: null})
  return form
}

export const convertFromFormFields = (form) => {
  Object.keys(form).map((key) => form[key] = form[key].value)
  return form
}

export const deepClone = (obj) => JSON.parse(JSON.stringify(obj));
