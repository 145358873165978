import React, { useEffect, useState, useCallback, useRef } from "react";
import axios, { config } from "../services/api";
import { validateEmptyChoice } from "../services/validators";

function FormSelectField(props) {

  const [fetchError, setFetchError] = useState(null);
  const [options, setOptions] = useState(null)

  let isRendered = useRef(false);

  const fetchOptions = useCallback(() => {
    if (props.apiUrl) {
      let url = props.apiUrl
      if (props.queryParams) {
        url = url + props.queryParams
      }
      axios
        .get(`/client/${url}`, config())
        .then(res => {
          if(isRendered.current) {
            setOptions(res.data)
          }
        })
        .catch(error => {
          console.log(error);
          if(isRendered.current) {
            setFetchError(`Unable to fetch ${props.name} options.`)
          }
        });
    }
  }, [props.apiUrl, props.queryParams, props.name]);

  useEffect(() => {
    isRendered.current = true;
    fetchOptions()
    return () => {
      isRendered.current = false;
    }
  }, [fetchOptions, props.message])

  const { warning=null, message=null, error=null, handleOnChange, value, label, name, isRequired, disabled } = props;

  const getOptions = () => {
    const { customOptions, isRequired } = props;
    const allOptions =
      options && options.length > 0
        ? options.map(option => ({
          value: option.id,
          label: option.name || option.location
        }))
        : [];
    if (customOptions && customOptions.length > 0) {
      customOptions.forEach(option => {
        allOptions.unshift(option);
      });
      if (isRequired) return allOptions;
    }
    allOptions.unshift({ value: 0, label: "-- Select an Option --" });

    return allOptions;
  };

  const onChange = e => {
    let currentError = isRequired ? validateEmptyChoice(e.target.value) : null;
    handleOnChange(e, currentError)
  }


  return (
    <>
      <div className="form-group mb-4">
        <label>
          {label}
          {isRequired && <span className="required"> *</span>}
          {error && <span className="error"> {error}</span>}
          {message && <div className="message"> -  {message}</div>}
          {warning && <div className="warning"> -  {warning}</div>}
          {fetchError && <div className="error"> {fetchError}</div>}
        </label>
        <div className="form-field">
          <select
            className="form-control"
            name={name}
            required={isRequired}
            value={value}
            onChange={onChange}
            disabled={disabled || false}
          >
          {getOptions().map((option) => {
            return (
              <option key={option.value} value={option.value}>{option.label}</option>
            )
          })}
          </select>
        </div>
      </div>
    </>
  );
}

export default FormSelectField;
