import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import axios, { config } from "../services/api";

import { convertIds, PATHS } from "../utils/constants";
import FormSelectField from "../components/FormSelectField";
import FormInputField from "../components/FormInputField";
import DrillingRigModal from "../components/modals/DrillingRigModal";
import TruckDriverModal from "../components/modals/TruckDriverModal";
import WellLocationModal from "../components/modals/WellLocationModal";
import { getId } from "../utils/constants";
import { convertFromFormFields, convertToFormFields, getUserScopeId, isAdminOrManager, deepClone } from "../utils/utils";
import { validateForm, validateEmpty, validateEmptyChoice, validateNotNegative, validateValidPercentage, validateInteger } from "../services/validators";

function AddJobLog(props) {
  const [form, setForm] = useState({
    disposal: {value: getUserScopeId() || undefined, error: null},
    date: {value: moment().format("YYYY-MM-DD"), error: null},
    operator: {value: undefined, error: null},
    drilling_rig: {value: undefined, error: null},
    well: {value: undefined, error: null},
    job_number: {value: "", error: null},
    solids_percentage: {value: "", error: null},
    bbl_count: {value: "", error: null},
    trucking_company: {value: undefined, error: null},
    truck_driver: {value: undefined, error: null},
    billed_to: {value: "", error: null},
    waste_type: {value: undefined, error: null},
    comment: {value: "", error: null},
    custom_rate: {value: "", error: null},
    billed: {value: false, error: null},
    spill_company: {value: undefined, error: null},
  });
  const [error, setError] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isOwner, setIsOwner] = useState(true);

  const validators = {
    disposal: [validateEmptyChoice],
    operator: [validateEmptyChoice],
    well: [validateEmptyChoice],
    job_number: [validateEmpty],
    solids_percentage: [validateEmpty, validateNotNegative, validateValidPercentage, validateInteger],
    bbl_count: [validateEmpty, validateNotNegative, validateInteger],
    trucking_company: [validateEmptyChoice],
    custom_rate: [validateNotNegative],
  }

  const fillForm = useCallback((newForm) => {
    newForm.billed = newForm.billed !== undefined ? newForm.billed === 'true' || newForm.billed : false
    setIsOwner(newForm.is_owner || isAdminOrManager())
    setIsReadOnly(newForm.billed || !isOwner)
    setForm(convertToFormFields(newForm))
  }, [isOwner])

  useEffect(() => {
    const jobLogId = getId()
    if (jobLogId) {
      setIsEdit(true);
      // check if the data is being passed through location
      if (props.location && props.location.state && Object.entries(props.location.state.form).length !== 0) {
        let {
          price,
          amount,
          operator_slash_location,
          nd_well_location,
          nd_well_file_number,
          ...newForm
        } = props.location.state.form;
        fillForm(newForm)
      } else {
        // since location does not have the data, we need to fetch it
        axios
          .get(`/client/job-log-detail/${jobLogId}`, config())
          .then(res => {
            let newForm = convertIds(res.data);
            fillForm(newForm)
          })
          .catch(error => {
            console.log(error);
            setError("Unable to get the job logs.")
          });
      }
    }
  }, [props.location, fillForm])

  const handleOnChange = (e, fieldError=null, fieldMessage=null, fieldWarning=null) => {
    let value = e.target.value
    if(e.target.name === 'billed') {
      value = e.target.checked
    }
    if (!isNaN(e.target.value)) {
      value = Number(value)
    }
    value = fieldWarning !== null ? undefined : value
    setForm({ ...form, [e.target.name]: {value: value, error: fieldError, message: fieldMessage, warning: fieldWarning} })
  }

  const fillErrors = errors => {
    let data = deepClone(form)
    for(let field of Object.keys(errors)) {
      if(data[field]) {
        data[field].error = errors[field]
        data[field].warning = null
        data[field].message = null
      }
    }
    setForm(data)
  }

  const handleSubmit = (e) => {
    setError(null);
    e.preventDefault();
    e.stopPropagation();
    let data = deepClone(form);
    let dataErrors = validateForm(data, validators);
    if(dataErrors) {
      setError('Invalid field(s)');
      fillErrors(dataErrors);
      window.scrollTo({top: 0,left: 0, behavior: 'smooth'});
      return;
    }
    data = convertFromFormFields(data);
    data = convertIds(data);
    axios({
      method: isEdit ? "put" : "post",
      url: isEdit ? "/client/job-log-update/" : "/client/job-log-create/",
      data,
      ...config(),
    })
      .then((res) => {
        if (res.data.success) {
          props.history.replace(PATHS.HOME);
        } else {
          console.log(res.data.error)
          setError('Invalid field(s)')
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  let clientId = ''
  if (form.billed_to) {
    clientId = form.billed_to?.value === 'trucking_company' ? form.trucking_company?.value : form.operator?.value
  }
  let wasteTypeParams = form.billed_to?.value && clientId ? `?client_id=${clientId}` : ''

  return (
    <div className="add-job-log">
      <h3 className="secondary mb-5">
        {isEdit ? "Edit" : "Add"}{" "}
        Job Log
        {!isOwner && isEdit &&
          <div> * You are not the owner/creator of this job log </div>
        }
      </h3>
      {error && <div className="error mb-3">Error: {error}</div>}
      <form noValidate onSubmit={handleSubmit}>
        <FormSelectField
          label="FluidTech Location"
          name="disposal"
          value={form.disposal?.value || undefined}
          error={form.disposal?.error}
          isRequired
          disabled={isReadOnly}
          apiUrl="disposal-list"
          handleOnChange={handleOnChange}
        />
        <FormInputField
          label="Date"
          name="date"
          value={form.date?.value || ""}
          type="date"
          disabled={isReadOnly}
          handleOnChange={handleOnChange}
        />
        <FormSelectField
          label="Operator"
          name="operator"
          value={form.operator?.value || undefined}
          error={form.operator?.error}
          isRequired
          disabled={isReadOnly}
          apiUrl="operator-list"
          handleOnChange={handleOnChange}
        />

        <div className="modal-form-field">
          <FormSelectField
            label="Drilling Rig"
            name="drilling_rig"
            value={form.drilling_rig?.value || undefined}
            message={form.drilling_rig?.message || undefined}
            warning={form.drilling_rig?.warning || undefined}
            disabled={isReadOnly}
            apiUrl="drilling-rig-list"
            queryParams={form.operator?.value ? `?operator_id=${form.operator.value}` : ''}
            handleOnChange={handleOnChange}
          />
          <DrillingRigModal id={form.drilling_rig?.value || undefined} handleUpdate={handleOnChange} formOperator={form.operator?.value || undefined}/>
        </div>

        <div className="modal-form-field">
          <FormSelectField
            label="Location"
            name="well"
            value={form.well?.value || undefined}
            error={form.well?.error}
            message={form.well?.message || undefined}
            warning={form.well?.warning || undefined}
            isRequired
            disabled={isReadOnly}
            apiUrl="well-list"
            queryParams={form.operator?.value ? `?operator_id=${form.operator.value}` : ''}
            handleOnChange={handleOnChange}
          />
          <WellLocationModal id={form.well?.value || undefined} handleUpdate={handleOnChange} formOperator={form.operator?.value || undefined}/>
        </div>

        <FormInputField
          label="Job #"
          name="job_number"
          value={form.job_number?.value || ""}
          error={form.job_number?.error}
          isRequired
          disabled={isReadOnly}
          handleOnChange={handleOnChange}
        />
        <FormInputField
          label="Solids %"
          name="solids_percentage"
          value={form.solids_percentage?.value || ""}
          error={form.solids_percentage?.error}
          type="number"
          isRequired
          disabled={isReadOnly}
          handleOnChange={handleOnChange}
        />
        <FormInputField
          label="#BBL"
          name="bbl_count"
          value={form.bbl_count?.value || ""}
          error={form.bbl_count?.error}
          type="number"
          isRequired
          disabled={isReadOnly}
          handleOnChange={handleOnChange}
        />
        <FormSelectField
          label="Trucking Company"
          name="trucking_company"
          value={form.trucking_company?.value || undefined}
          error={form.trucking_company?.error}
          isRequired
          apiUrl="trucking-company-list"
          disabled={isReadOnly}
          handleOnChange={handleOnChange}
        />

        <div className="modal-form-field">
          <FormSelectField
            label="Driver Name"
            name="truck_driver"
            value={form.truck_driver?.value || undefined}
            error={form.truck_driver?.error}
            message={form.truck_driver?.message || undefined}
            warning={form.truck_driver?.warning || undefined}
            apiUrl="truck-driver-list"
            queryParams={form.trucking_company?.value ? `?trucking_company_id=${form.trucking_company.value}` : ''}
            disabled={isReadOnly}
            handleOnChange={handleOnChange}
          />
          <TruckDriverModal id={form.truck_driver?.value || undefined} handleUpdate={handleOnChange} formCompany={form.trucking_company?.value || undefined}/>
        </div>

        <FormSelectField
          label="Bill To"
          name="billed_to"
          value={form.billed_to?.value || undefined}
          isRequired
          disabled={isReadOnly}
          customOptions={[
            { value: "operator", label: "Operator" },
            { value: "trucking_company", label: "Trucking Company" },
          ]}
          handleOnChange={handleOnChange}
        />
        <FormSelectField
          label="Waste Type"
          name="waste_type"
          value={form.waste_type?.value || undefined}
          apiUrl={"waste-type-list"}
          queryParams={wasteTypeParams}
          disabled={isReadOnly}
          handleOnChange={handleOnChange}
        />
        <FormInputField
          label="Comment"
          name="comment"
          value={form.comment?.value || undefined}
          type="textarea"
          disabled={isReadOnly}
          handleOnChange={handleOnChange}
        />
        {isAdminOrManager() ? (
          <FormInputField
            label="Custom Rate ($)"
            name="custom_rate"
            value={form.custom_rate?.value || ""}
            error={form.custom_rate?.error}
            type="number"
            disabled={isReadOnly}
            handleOnChange={handleOnChange}
          />
        ) :
          <div className="read-only-field">
            Custom Rate ($) : {form.custom_rate?.value && form.custom_rate?.value !== '' ? form.custom_rate?.value : <b>Not in use</b>}
          </div>
        }
        {isAdminOrManager() ? (
          <FormInputField
            label="Billed"
            name="billed"
            value={form.billed?.value}
            type="checkbox"
            handleOnChange={handleOnChange}
          />
        ) :
          <div className="read-only-field">
            Billed Status: <b>{form.billed?.value ? 'Billed' : 'Not Billed'}</b>
          </div>
        }
        <input
          type="submit"
          value="Submit"
          className="btn my-4 w-25 float-right"
        />
      </form>
    </div>
  );
}

export default AddJobLog;
