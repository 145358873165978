import React, {useState, useEffect} from "react";
import ReactTable from "react-table-6";
import axios, { config } from "../services/api";

import { PATHS } from "../utils/constants";
import { getFormattedUserScope, getUserScope, getUserScopeId, isFluidtechEmployee, updateEmployeeScopeType } from "../utils/utils";
import { convertIds } from "../utils/constants";
import FormSelectField from "../components/FormSelectField";

function JobLogs(props) {

  const [jobLogs, setJobLogs] = useState([]);
  const [error, setError] = useState("");
  const [canEditLogs, setCanEditLogs] = useState(false);
  const [currentEmployeeLocation, setCurrentEmployeeLocation] = useState(getUserScopeId() || 0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/client/job-log-list", config())
      .then(res => {
        setJobLogs(res.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setError("Unable to get the job logs.");
        setLoading(false);
      });
    if (isFluidtechEmployee()) {
      setCanEditLogs(true);
    }
  }, [currentEmployeeLocation])

  const { history } = props;

  const handleEmployeeLocationChange = e => {
    const id = e.target.value
    if(Number(id) !== 0) {
      updateEmployeeScopeType(id).then(() => {
        setCurrentEmployeeLocation(id)
      })
    }
  }

  return (
    <div id="job-logs">
      <div className="d-flex">
        <div className={getUserScope() === 'employee' ? 'role-wrapper' : ''}>
          <div className="role-display">Welcome, {getFormattedUserScope()}</div>
          {getUserScope() === 'employee' ? (
            <div className='employee-disposal'>
              {!loading ? (
                <FormSelectField
                  label="Current Location/Disposal"
                  name="current_location"
                  value={currentEmployeeLocation}
                  apiUrl="disposal-list"
                  disabled={loading}
                  handleOnChange={handleEmployeeLocationChange}
                  />
              ): <div className="loading-message">...retrieving location data</div> }
            </div>
          ) : ''}
        </div>
        {canEditLogs && (
          <button
            className="btn mb-4 add-entry"
            onClick={() => history.push(PATHS.ADD_LOG)}
          >
            + Add Log Entry
          </button>
        )}
      </div>

      {error && <div>{error}</div>}

      <ReactTable
        data={jobLogs}
        defaultPageSize={jobLogs.length < 10 ? 10 : 20}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toLowerCase()
            .includes(filter.value.toLowerCase())
        }
        defaultFiltering
        getTdProps={(state, rowInfo) => ({
          onClick: () => {
            let id = rowInfo.original.id
            let data = convertIds(rowInfo.original)
            if (rowInfo && canEditLogs) {
              history.push({
                pathname: `${PATHS.ADD_LOG}/${id}`,
                state: { form: { ...data } }
              });
            }
          }
        })}
        columns={[
          {
            Header: "FluidTech Location",
            accessor: "disposal.name",
            minWidth: 150
          },
          { Header: "Date", accessor: "date" },
          { Header: "Operator", accessor: "operator.name" },
          { Header: "Drilling Rig", accessor: "drilling_rig.name" },
          { Header: "Job #", accessor: "job_number" },
          { Header: "Location", accessor: "well.location", minWidth: 200 },
          { Header: "Solids %", accessor: "solids_percentage" },
          {
            Header: "Price",
            accessor: "price",
            Cell: ({ value }) => Number(value)?.toFixed(2)
          },
          { Header: "#BBL", accessor: "bbl_count" },
          { Header: "Waste Type", accessor: "waste_type.name" },
          { Header: "Trucking Company", accessor: "trucking_company.name" },
          { Header: "Truck Driver", accessor: "truck_driver.name" },
          {
            Header: "Bill To",
            id: "billed_to",
            accessor: row =>
              row.billed_to === "operator"
                ? row.operator && row.operator.name
                : row.trucking_company && row.trucking_company.name
          },
          { Header: "Comment", accessor: "comment", minWidth: 200 },
          { Header: "Amount", accessor: "amount" },
          {
            Header: "Billed",
            id: "billed",
            accessor: "billed",
            Cell: ({ value }) => (value ? "YES" : "NO"),
            filterMethod: (filter, row) => {
              if (filter.value === "all") return true;
              const rowId = row[filter.id];
              return filter.value === "true" ? rowId : !rowId;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%", height: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">ALL</option>
                <option value="true">YES</option>
                <option value="false">NO</option>
              </select>
            )
          },
          {
            Header: "Operator / Well Name",
            accessor: "operator_slash_location",
            minWidth: 200
          },
          {
            Header: "ND Well Location",
            accessor: "nd_well_location",
            minWidth: 150
          },
          { Header: "Well File Number", accessor: "nd_well_file_number" }
        ]}
        className="-striped -highlight"
      />
    </div>
  );
}

export default JobLogs;
