export const PATHS = {
  HOME: "/job-logs",
  ADD_LOG: "/add-job-log",
  DETAIL_REPORT: "/detail-report",
  SUMMARY_REPORT: "/summary-report",
}

export function getId() {
  let location = window.location.toString().split('/')
  let id = Number(location[location.length - 1])
  return !isNaN(id) ? id : null;
}

export function convertIds(data) {
  for (let key in Object.keys(data)) {
    const value = Object.keys(data)[key];
    if (data[value] && data[value].id) {
      data[value] = data[value].id;
    }
    if (data[value] === "") {
      data[value] = undefined
    }
  }
  return data
}
