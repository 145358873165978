import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn, isFluidtechEmployee, isAdminOrManager } from "../utils/utils";

function PrivateRoute(props) {
  const [canAccessUrl, setCanAccessUrl] = useState(false)
  const [shouldRedirect, setShouldRedirect] = useState(false)

  useEffect(() => {
    if (isFluidtechEmployee()) {
      if(props.forAdminOrManager) {
        setCanAccessUrl(isAdminOrManager());
        setShouldRedirect(!isAdminOrManager());
      } else {
        setCanAccessUrl(true)
      }
    } else {
      if(props.forClient) {
        setShouldRedirect(false);
        setCanAccessUrl(true);
      } else {
        setShouldRedirect(true);
      }
    }
  }, [props.forClient, props.forAdminOrManager])

  const { component: Component, ...rest } = props;

  return (
    isLoggedIn() ? (
      <Route
        {...rest}
        render={props =>
          canAccessUrl ? <Component {...props} /> : shouldRedirect ? <Redirect to='/job-logs' /> : null
        }
      />
    ) : <Redirect to='/' />
  );
}

export default PrivateRoute;
