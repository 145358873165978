import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import Header from "./components/Header";
import Landing from "./pages/Landing";
import JobLogs from "./pages/JobLogs";
import AddJobLog from "./pages/AddJobLog";
import DetailReport from "./pages/DetailReport";
import SummaryReport from "./pages/SummaryReport";

import "./static/app.scss";

function App() {
  return (
    <Router>
      <Header />
      <div id="app" className="p-5">
        <Switch>
          <Route exact path="/" component={Landing} />
          <PrivateRoute forClient exact path="/job-logs" component={JobLogs} />
          <PrivateRoute
            strict
            path="/add-job-log"
            component={AddJobLog}
          />
          <PrivateRoute
            strict
            path="/detail-report"
            component={DetailReport}
            forAdminOrManager
          />
          <PrivateRoute
            strict
            path="/summary-report"
            component={SummaryReport}
            forAdminOrManager
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
