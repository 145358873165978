import React, { useState, useEffect } from "react";
import axios, { config } from "../../services/api";

import Modal from "./Modal";
import FormInputField from "../FormInputField";
import FormSelectField from "../FormSelectField";

import edit from "../../static/images/icons/edit-icon.png";
import add from "../../static/images/icons/plus-icon.png";
import { deepClone, convertFromFormFields } from "../../utils/utils";
import { convertIds } from "../../utils/constants";
import { validateForm, validateEmpty, validateEmptyChoice } from "../../services/validators";

function TruckDriverModal(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [form, setForm] = useState({
    name: { value: "", error: null },
    trucking_company: { value: 0, error: null },
  });
  const [error, setError] = useState("");
  const [isNew, setIsNew] = useState(true);

  useEffect(() => {
    setForm({
      name: { value: "", error: null },
      trucking_company: { value: props.formCompany, error: null }
    })
  }, [props.formCompany])

  const validators = {
    name: [validateEmpty],
    trucking_company: [validateEmptyChoice]
  }

  const fetchTruckDriver = () => {
    const { id } = props;
    axios
      .get(`/client/truck-driver-list?id=${id.id ? id.id : id}`, config())
      .then(res => {
        if (res.data && res.data[0]) {
          setForm({
            name: { value: res.data[0].name, error: null },
            trucking_company: { value: res.data[0].trucking_company.id, error: null }
          })
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleEdit = () => {
    if (props.id) {
      fetchTruckDriver();
    }
    setModalOpen(!modalOpen);
    setIsNew(false);
  };

  const handleAdd = () => {
    if (props.id) {
      setForm({
        name: { value: "", error: null },
        trucking_company: { value: 0, error: null }
      })
    }
    setModalOpen(!modalOpen);
    setIsNew(true);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  };

  const handleOnChange = (e, fieldError = null) => {
    let value = e.target.value
    value = isNaN(value) ? value : Number(value)
    let warning = e.target.name === 'trucking_company' && props.formCompany !== value ? 'this company does not match the form\'s trucking company' : null
    setForm({ ...form, [e.target.name]: { value: value, error: fieldError, warning: warning } })
  };

  const fillErrors = errors => {
    let data = deepClone(form)
    for (let field of Object.keys(errors)) {
      if (data[field]) {
        data[field].error = errors[field]
      }
    }
    setForm(data)
  }

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    let data = deepClone(form);
    let dataErrors = validateForm(data, validators);
    if (dataErrors) {
      setError('Invalid field(s)');
      fillErrors(dataErrors);
      return;
    }
    data = convertFromFormFields(data);
    data = convertIds(data);
    data['id'] = props.id
    axios({
      method: isNew ? "post" : "put",
      url: isNew
        ? "/client/truck-driver-create/"
        : "/client/truck-driver-update/",
      data,
      ...config()
    })
      .then(res => {
        if (res.data.success) {
          const { data } = res.data;
          toggleModal();
          let message = isNew ?  `successully created ${res.data?.data?.name || ""} ` : `successfully updated ${ res.data?.data?.name || ""}`
          let warning = props.formCompany !== res.data?.data?.trucking_company ? 'this value was reset, the company on the form does not match this drivers\'s company' : null
          props.handleUpdate({ target: { value: data.id, name: "truck_driver" } }, null, message, warning);
        } else {
          setError("Invalid field(s)")
        }
      })
      .catch(error => {
        console.log("error", error);
      });
  };

  return (
    <div>
      <div className="icon-wrapper">
        <img className="icon" src={add} alt="add" onClick={handleAdd} />
        {props.id && <img className="icon" src={edit} alt="edit" onClick={handleEdit} />}
      </div>
      <Modal
        title="Truck Driver"
        isOpen={modalOpen}
        onClose={toggleModal}
        onSave={handleSubmit}
        formId="truck-driver-form"
      >
        <div className="add-job-log">
          <form
            noValidate
            className="full-width"
            id="truck-driver-form"
            onSubmit={handleSubmit}
          >
            {error && <div className="error mb-3">Error: {error}</div>}
            <FormInputField
              label="Name"
              name="name"
              value={form.name?.value || ""}
              error={form.name?.error}
              isRequired
              type="text"
              handleOnChange={handleOnChange}
            />
            <FormSelectField
              label="Trucking Company"
              name="trucking_company"
              isRequired
              value={form.trucking_company?.value || 0}
              error={form.trucking_company?.error}
              warning={form.trucking_company?.warning || undefined}
              apiUrl="trucking-company-list"
              handleOnChange={handleOnChange}
            />
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default TruckDriverModal;
